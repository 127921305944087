<template>
  <el-dialog :title="title" v-model="visibility" width="600px" top="20vh" lock-sroll="false">
    <div class="outer2 flex-col invoice_dailog">

      <div class="box2 flex-row">
        <div class="main2">
          <span class="txt1">*</span>
          <span class="info1">{{L['抬头类型：']}}</span>
        </div>
        <el-radio @change="changeTitle" class="label2" v-model="titleType" label="1">{{L['个人']}}</el-radio>
        <el-radio @change="changeTitle" v-model="titleType" label="2">{{L['公司']}}</el-radio>
      </div>
      <div class="box3 flex-row">
        <div class="outer3">
          <span class="info2">*</span>
          <span class="info3">{{L['发票抬头：']}}</span>
        </div>
        <div class="outer4 flex-col">
          <el-input v-model="invoiceTitle" :placeholder="titleType == 1 ? L['请输入个人真实姓名'] : L['请输入发票抬头']" maxlength="20"
            show-word-limit />
        </div>
      </div>
      <div class="box4 flex-row">
        <div class="main3">
          <span class="word3">*</span>
          <span class="info5">{{L['发票类型：']}}</span>
        </div>
        <span v-if="titleType == 1" class="label4">{{L['增值税普通发票']}}</span>
        <template v-else>
          <el-radio class="label4" v-model="invoiceType" label="1">{{L['增值税普通发票']}}</el-radio>
          <el-radio v-model="invoiceType" label="2">{{L['增值税专用发票']}}</el-radio>
        </template>
      </div>
      <div class="box5 flex-row" v-if="titleType == 2">
        <div class="main4">
          <span class="word6">*</span>
          <span class="info6">{{L['统一社会信用代码：']}}</span>
        </div>
        <div class="main5 flex-col">
          <el-input v-model="socialCreditCode" :placeholder="L['请输入统一社会信用代码']" show-word-limit maxlength="18" />
        </div>
      </div>
      <template v-if="titleType == 2 && invoiceType == 2">
        <div class="box5 flex-row" style="height:110px;align-items:center;">
          <div class="main4">
            <span class="word6">*</span>
            <span class="info6">{{L['注册场所地址：']}}</span>
          </div>
          <div class="main5 flex-col">
            <el-input type="textarea" :rows="5" v-model="registerAddr" autocomplete="off" :placeholder="L['请输入注册场所地址']"
              clearable maxlength="100" resize="none" style="width:346px"></el-input>
          </div>
        </div>
        <div class="box5 flex-row">
          <div class="main4">
            <span class="word6">*</span>
            <span class="info6">{{L['注册固定电话：']}}</span>
          </div>
          <div class="main5 flex-col">
            <el-input v-model="registerPhone" :placeholder="L['请输入注册固定电话']" maxlength="13" />
          </div>
        </div>
        <div class="box5 flex-row">
          <div class="main4">
            <span class="word6">*</span>
            <span class="info6">{{L['开户银行：']}}</span>
          </div>
          <div class="main5 flex-col">
            <el-input v-model="bankName" :placeholder="L['请输入开户银行名称']" maxlength="18" />
          </div>
        </div>
        <div class="box5 flex-row">
          <div class="main4">
            <span class="word6">*</span>
            <span class="info6">{{L['银行账号：']}}</span>
          </div>
          <div class="main5 flex-col">
            <el-input type="number" v-model="bankAccount" :placeholder="L['请输入银行账号']" maxlength="20"></el-input>
          </div>
        </div>
      </template>
      <div class="box2 flex-row">
        <div class="main2">
          <span class="txt1">*</span>
          <span class="info1">{{L['默认发票：']}}</span>
        </div>
        <el-radio class="label2" v-model="isDefault" label="1">{{L['是']}}</el-radio>
        <el-radio v-model="isDefault" label="0">{{L['否']}}</el-radio>
      </div>
      <div class="box7 flex-row">
        <button class="outer5 flex-col" @click="closeDilog">
          <span class="word7">{{L['取消']}}</span>
        </button>
        <button class="outer6 flex-col" @click="addInvoice">
          <span class="txt4">{{L['确定']}}</span>
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { ref, getCurrentInstance } from "vue";
import { ElMessage } from "element-plus";
export default {
  props: ["memberId"],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const title = ref(L['添加发票抬头']);
    const visibility = ref(false);
    const titleType = ref('1');
    const invoiceTitle = ref('');
    const invoiceType = ref('1');
    const socialCreditCode = ref('');
    const isDefault = ref('1');
    const isEdit = ref(false); //是否是编辑
    const invoiceId = ref('');
    const bankAccount = ref('');
    const bankName = ref('');
    const registerAddr = ref('');
    const registerPhone = ref('');
    const resetData = () => { //清空数据
      titleType.value = '1';
      invoiceType.value = '1';
      isDefault.value = '1';
      isEdit.value = false;
      invoiceId.value = '';
      invoiceTitle.value = '';
      socialCreditCode.value = '';
      bankAccount.value = '';
      bankName.value = '';
      registerAddr.value = '';
      registerPhone.value = '';
    }

    const addInvoice = () => {
      let nameReg = /^([\u4e00-\u9fa5]|[a-zA-Z])+$/
      if (!invoiceTitle.value) {
        ElMessage.warning(L['请输入发票抬头!'])
        return;
      } else if (!nameReg.test(invoiceTitle.value)) {
        ElMessage.warning(L['请输入正确的发票抬头!'])
        return;
      }
      let param = {
        titleType: titleType.value, //抬头类型：1-个人；2-公司
        invoiceTitle: invoiceTitle.value, //发票抬头(通用信息）
        invoiceType: invoiceType.value, //发票类型：1-普通发票；2-增值税发票
        isDefault: isDefault.value, //是否默认发票：0-非默认发票，1-默认发票
        // taxCode: '', //纳税人识别号(通用信息）
        // invoiceContent: '', //发票内容：1-产品明细；2-产品类别
        bankAccount: bankAccount.value, //银行帐户(专用发票）
        bankName: bankName.value, //开户银行(专用发票）
        registerAddr: registerAddr.value, //注册地址(专用发票）
        registerPhone: registerPhone.value, //注册电话(专用发票）
      }
      if (titleType.value == '2') {
        let reg = /^[0-9A-Z]{2}\d{6}[0-9A-Z]{10}$/;
        let regMobile = /^(1[3-9]\d{9})$/;
        let regTel = /^(\d{3,4}-)?\d{6,9}$/;
        if (!socialCreditCode.value) {
          ElMessage.warning(L['请输入统一社会信用代码!'])
          return;
        } else if (!reg.test(socialCreditCode.value)) {
          ElMessage.warning(L['请输入正确的统一社会信用代码!'])
          return;
        }
        param.socialCreditCode = socialCreditCode.value; //统一社会信用代码
        if (invoiceType.value == 2) {
          if (!registerAddr.value) {
            ElMessage.warning(L['请输入注册场所地址!'])
            return;
          } else if (!registerPhone.value) {
            ElMessage.warning(L['请输入注册固定电话!'])
            return;
          } else if (!regMobile.test(registerPhone.value) && !regTel.test(registerPhone.value)) {
            ElMessage.warning(L['请输入正确的注册固定电话!'])
            return;
          } else if (!bankName.value) {
            ElMessage.warning(L['请输入开户银行名称!'])
            return;
          } else if (!bankAccount.value) {
            ElMessage.warning(L['请输入银行账号!'])
            return;
          }
        }
      }

      if (isEdit.value) {
        param.invoiceId = invoiceId.value
        proxy
          .$post('v3/member/front/invoiceTitle/update', param)
          .then(res => {
            if (res.state == 200) {
              ElMessage.success(res.msg);
              resetData();
              emit("refresh");
            } else {
              ElMessage.error(L['编辑失败']);
            }
          })
      } else {
        proxy
          .$post('v3/member/front/invoiceTitle/add', param)
          .then(res => {
            if (res.state == 200) {
              ElMessage.success(res.msg);
              resetData();
              emit("refresh");
            } else {
              ElMessage.error(res.msg);
            }
          })
      }
    };

    const closeDilog = () => {
      resetData();
      visibility.value = false
    };

    const changeTitle = () => { //切换发票抬头
      invoiceTitle.value = '';
      socialCreditCode.value = '';
      invoiceType.value = '1';
    };

    return {
      L,
      title,
      titleType,
      invoiceType,
      visibility,
      invoiceTitle,
      socialCreditCode,
      isEdit,
      isDefault,
      invoiceId,
      resetData,
      addInvoice,
      changeTitle,
      closeDilog,
      bankAccount,
      bankName,
      registerAddr,
      registerPhone,
    };
  },
};
</script>
<style lang="scss">
.invoice_dailog {
  .box1 {
    height: 46px;
    border-radius: 4px 4px 0 0;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: center;

    .bd1 {
      width: 559px;
      height: 16px;
      justify-content: space-between;
    }

    .word1 {
      width: 82px;
      height: 14px;
      display: block;
      overflow-wrap: break-word;
      color: $colorF;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium;
      white-space: nowrap;
      line-height: 14px;
      text-align: center;
      margin-top: 1px;
    }
  }

  .box2 {
    width: 241px;
    height: 16px;
    margin: 30px 0 0 78px;

    .main2 {
      z-index: 6;
      width: 77px;
      height: 14px;
      overflow-wrap: break-word;
      text-align: left;
      white-space: nowrap;
      font-size: 0;
    }

    .txt1 {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular;
      color: $colorE;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .info1 {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular;
      color: $colorF;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .label2 {
      z-index: 18;
      margin-left: 6px;
    }
  }

  .box3 {
    z-index: auto;
    width: 423px;
    height: 34px;
    justify-content: space-between;
    margin: 20px 0 0 78px;

    .outer3 {
      z-index: 28;
      width: 77px;
      height: 14px;
      overflow-wrap: break-word;
      text-align: left;
      white-space: nowrap;
      margin-top: 10px;
      font-size: 0;
    }

    .info2 {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular;
      color: $colorE;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .info3 {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular;
      color: $colorF;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .outer4 {
      z-index: 30;
      height: 34px;
      border-radius: 2px;
      width: 340px;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .box4 {
    z-index: auto;
    width: 381px;
    height: 16px;
    margin: 20px 0 0 78px;

    .main3 {
      z-index: 33;
      width: 77px;
      height: 14px;
      overflow-wrap: break-word;
      text-align: left;
      white-space: nowrap;
      font-size: 0;
    }

    .word3 {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular;
      color: $colorE;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .info5 {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular;
      color: $colorF;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .label4 {
      z-index: 36;
      margin-left: 10px;
    }
  }

  .box5 {
    width: 479px;
    height: 34px;
    justify-content: flex-end;
    margin: 20px 0 0 32px;

    .main4 {
      // width: 133px;
      height: 14px;
      overflow-wrap: break-word;
      text-align: left;
      white-space: nowrap;
      margin-top: 10px;
      font-size: 0;
    }

    .word6 {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular;
      color: $colorE;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .info6 {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular;
      color: $colorF;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .main5 {
      z-index: 57;
      height: 34px;
      border-radius: 2px;
      width: 340px;
      justify-content: center;
      align-items: flex-start;
      margin-left: 5px;
    }
  }

  .box7 {
    width: 200px;
    height: 34px;
    justify-content: space-between;
    margin: 50px auto 0;

    .outer5 {
      height: 34px;
      border-radius: 2px;
      border: 1px solid $colorI;
      background-color: #FFFFFF;
      width: 90px;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .word7 {
        width: 28px;
        height: 20px;
        display: block;
        overflow-wrap: break-word;
        color: $colorG;
        font-size: 14px;
        letter-spacing: -0.33764705061912537px;
        font-family: PingFangSC-Regular;
        white-space: nowrap;
        line-height: 20px;
        text-align: center;
      }
    }

    .outer6 {
      height: 34px;
      border-radius: 2px;
      background-color: $colorMain;
      width: 90px;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;

      .txt4 {
        width: 28px;
        height: 20px;
        display: block;
        overflow-wrap: break-word;
        color: #FFFFFF;
        font-size: 14px;
        letter-spacing: -0.33764705061912537px;
        font-family: PingFangSC-Regular;
        white-space: nowrap;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}
</style>
<style>
.invoice_dailog .el-input__inner {
  line-height: 1px !important;
  /**
		* 解决el-input设置类型为number时，中文输入法光标上移问题
		**/
}

.invoice_dailog .outer4 .el-input,
.invoice_dailog .main5 .el-input {
  width: 346px;
  height: 34px;
}

.invoice_dailog .outer4 .el-input__inner,
.invoice_dailog .main5 .el-input__inner {
  width: 346px;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
}

.invoice_dailog input[type=number] {
  -moz-appearance: textfield;
}

.invoice_dailog input[type=number]::-webkit-inner-spin-button,
.invoice_dailog input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>